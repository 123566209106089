<template>
  <div>
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <div class="accout-content clearfix" style="display:flex">
          <!-- 左边 -->
          <user-left mark="exhibition" :userId="userId" />
          <!-- 右边 -->
          <a-card :bordered="false" class="card-right" style="width:100%">
            <div style="font-size:18px;font-weight:bold;margin-bottom:20px">
              {{ $t("user.exhibition.info") }}
            </div>
            <div class="first">
              <span>{{ $t("exhibitionName") }}:</span>
              <span style="width: 600px;">{{
                list.exhibition.exhibitionName
              }}</span>
            </div>
            <div class="second">
              <span>{{ $t("exhibitionTitle") }}:</span>
              <span>{{ list.exhibition.exhibitionTitle }}</span>
            </div>
            <div class="second">
              <span>{{ $t("exhibitionAddress") }}:</span>
              <span>{{ list.exhibition.exhibitionAddress }}</span>
            </div>
            <div class="second">
              <span>{{ $t("exhibitionCategory") }}:</span>
              <div style="display:inline-block;font-size:16px">
                <span
                  v-for="(item, index) in list.exhibition.categories"
                  :key="index"
                  >{{ index + 1 }}.{{ item.topCategoryName }}-{{
                    item.childCategoryName
                  }}
                </span>
              </div>
            </div>
            <div v-if="list.positions != ''">
              <div
                style="font-size:18px;font-weight:bold;margin-bottom:20px;margin-top:20px"
              >
                {{ $t("exhibitionZone") }}
              </div>
              <div
                v-for="(item, index) in list.positions"
                :key="index"
                style="text-align:left;font-size:16px;margin-bottom:10px"
              >
                {{ $t("industry") }}:{{ item.categoryName }} {{ $t("zone") }}:{{
                  item.zoneName
                }}
                {{ $t("zoneNumber") }}:{{ item.nums }}
              </div>
            </div>
            <div style="font-size:18px;font-weight:bold;margin-bottom:20px">
              {{ $t("user.exhibition.contact-product") }}
            </div>
            <a-table
              :row-key="
                (record) => {
                  return record.productId;
                }
              "
              :columns="columns"
              :data-source="productList"
            >
              <a
                slot="action"
                slot-scope="text, record"
                @click="unguanlian(record.productId)"
              >
                {{ $t("user.off") }}
              </a>
            </a-table>
            <div style="font-size:18px;font-weight:bold;margin-bottom:20px">
              {{ $t("user.exhibition.no-contact-product") }}
            </div>
            <a-table
              :row-key="
                (record) => {
                  return record.productId;
                }
              "
              :columns="columns"
              :data-source="unProduct"
            >
              <a
                slot="action"
                slot-scope="text, record"
                @click="guanlian(record.productId)"
              >
                {{ $t("user.on") }}
              </a>
            </a-table>
          </a-card>
        </div>
      </div>
    </div>
    <!-- 公共尾部 -->
    <Footer></Footer>
  </div>
</template>

<style>
.main-content .card-right {
  width: 100%;
}
.first,
.second {
  text-align: left;
}
.first > span,
.second > span {
  font-size: 16px;
  width: 140px;
  display: inline-block;
}
.second > span:nth-child(2) {
  width: 600px;
}
input[type="text"] {
  width: 300px;
  border: 1px solid #787878;
  margin-left: 10px;
}
.second {
  margin-top: 20px;
}
.submit {
  width: 200px;
  height: 40px;
  margin-top: 40px;
  border: 1px solid #d9d9d9;
  line-height: 40px;
  background: #ff4d4f;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
}
.ant-select-selection--single {
  margin-left: 10px;
  border: 1px solid black;
}
.ant-upload.ant-upload-select-picture-card {
  margin-left: 10px;
}
.ant-calendar-picker {
  margin-left: 10px;
}
.queot {
  text-align: left;
  font-size: 16px;
  margin-top: 10px;
}
.ant-btn-primary {
  margin-left: 20px;
}
</style>

<script>
import UserHeader from "./components/Header";
import UserLeft from "./components/Left";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Editor from "@/components/Editor/TinyMceEditor";

export default {
  name: "ExhibitionDetail",
  inject: ["reload"], // 注入依赖
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header,
  },
  data() {
    return {
      userId: this.$route.params.userId,
      id: this.$route.params.id,
      list: {
        exhibition: {
          categories: {},
        },
        positions: {},
      },
      productList: [],
      unProduct: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('user.number'),
          dataIndex: "productId",
        },
        {
          title: this.$t('user.product.name'),
          dataIndex: "productName",
        },
        {
          title: this.$t('user.operation'),
          scopedSlots: { customRender: "action" },
        },
      ];
    },
  },
  mounted() {
    this.getList();
    this.getProductList();
    this.getUnProductList();
  },
  methods: {
    //获取展会详情
    getList() {
      this.$common
        .fetchList("/user/joined/exhibition/detail/" + this.id, {})
        .then((data) => {
          if (data.code === 200) {
            const result = data.data;
            this.list = result;
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
    //展会已关联的商品列表
    getProductList() {
      this.$common
        .fetchList(
          "/exhibition/product/related/lists?exhibitionId=" + this.id,
          {}
        )
        .then((data) => {
          if (data.code === 200) {
            const result = data.datas;
            this.productList = result.lists;
            console.log(222, this.productList);
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
    //展会未关联商品列表
    getUnProductList() {
      this.$common
        .fetchList(
          "/exhibition/product/unrelate/lists?exhibitionId=" + this.id,
          {}
        )
        .then((data) => {
          if (data.code === 200) {
            const result = data.datas;
            this.unProduct = result.lists;
            console.log(111, this.unProduct);
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
    //关联商品
    guanlian(productId) {
      const params = {
        exhibitionId: this.id,
        productId: productId,
      };
      this.$common
        .handlePost("/exhibition/product/add", params)
        .then((data) => {
          if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.reload();
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
    //取消关联
    unguanlian(productId) {
      const params = {
        exhibitionId: this.id,
        productId: productId,
      };
      this.$common
        .handleGet("/exhibition/product/delete", params)
        .then((data) => {
          if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.reload();
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
  },
};
</script>
